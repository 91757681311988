import 'bootstrap';
import ClipboardJS from 'clipboard/dist/clipboard';

require('datatables.net-bs4');

$(document).ready(() => {

    if ($(".sticky-top")[0]){
        const observer = new IntersectionObserver( 
            ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
            {threshold: [1]}
        );

        observer.observe(document.querySelector('.sticky-top'));
    }

    // tooltips only when using a mouse
    if(!('ontouchstart' in window)) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    $('table.datatable').DataTable();

    $('#editions_datatable').DataTable({
        serverSide: true,
        ajax: '/admin/editions.json'
    });

    $('#documents_datatable').DataTable({
        serverSide: true,
        ajax: '/admin/documents.json',
        searching: false,
    });


    $(window).scroll(() => {
        var height = $(window).scrollTop();
        if (height > 3000) {
            $('.b2t').fadeIn();
        } else {
            $('.b2t').fadeOut();
        }
    });

    $('.search-form input[type=radio]').click(() => {
        if ($('#search-field').val().length > 0) {
            $('.search-form').submit();
        }
    })


    // copy to clipboard
    function hideTooltip() {
      setTimeout(function() {
        $('.copy-btn').tooltip('dispose');
      }, 1000);
    }
    
    var clipboard = new ClipboardJS('.copy-btn');

    clipboard.on('success', function(e){
      $(e.trigger).closest('.copy-btn').tooltip('enable').tooltip('show');
      hideTooltip();
  });
});